<template>
  <div style="height: 100%">
    <div class="d-flex flex-column justify-center align-center" style="height: calc(100%)">
      <lottie-animation v-cloak
                        style="max-width: 200px"
                        :autoPlay="false"
                        ref="animation"
                        :animationData="require('@/assets/error.json')"></lottie-animation>
      <v-fade-transition>
        <div class="pa-4 mt-12 d-flex flex-column align-center text-center" v-if="show">
          <div class="text-h4">{{ $t('ErrorPageMessage') }}</div>
          <div class="text-body-1 mt-4">{{ $t('ErrorPageMessage01') }}</div>
          <div class="text-body-1 mt-2">{{ $t('InLoveWithInnerKen') }}</div>
        </div>
      </v-fade-transition>
    </div>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "OrderComplete",
  data: function () {
    return {show: false};
  },
  mounted() {
    setTimeout(() => {
      this.$refs.animation.play()
      setTimeout(() => {
        this.show = true
      }, 1000)
    }, 1000)
  }
}
</script>

<style scoped>

</style>
