<template>
  <v-bottom-sheet width="100vw" max-width="600px" v-model="realShow" class="dishDetailSheet">
    <v-card v-if="dish" id="card"
            style="border-top-right-radius: 16px;border-top-left-radius: 16px;position: relative;overflow: scroll"
            class="d-flex flex-column align-center"
            max-height="calc(100vh - 24px)">
      <div style="max-height: calc(100vh - 24px);overflow-y: scroll;width: 100%">
        <v-img
          width="100%"
          :aspect-ratio="16/12"
          :src="imageRoot+dish.image">
          <template #placeholder>
            <v-card>
              <v-responsive :aspect-ratio="1">
                <div style="height: 100%" class="d-flex align-center justify-center">
                  <v-icon large>mdi-image</v-icon>
                </div>
              </v-responsive>
            </v-card>
          </template>
        </v-img>
        <div class="pa-4" style="width: 100%">
          <div class="text-h6 font-weight-black">{{ dish.dishName }}</div>
          <div class="text-body-2 text--secondary mt-1" v-html="dish.dishDesc || $t('EmptyMenuDesc')"></div>
        </div>
        <template v-if="dish.modInfo&&show&&computedOption.length>0">
          <div class="pb-16 px-4" style="width: 100%;">
            <v-divider class="mb-3"/>
            <template v-for="item in computedOption">
              <div class="text-subtitle-2 d-flex align-center" :key="'mod2head'+item.id">
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-card class="px-2 py-0" elevation="0" style="border-radius: 24px" color="primary black--text"
                        v-if="item.multiSelect==='1'&&mod[item.id]&&mod[item.id].length>0">
                  + {{ mod[item.id] && mod[item.id].length }}
                </v-card>
              </div>
              <div class="mt-1" :key="'mod2'+item.id">
                <v-item-group
                  v-model="mod[item.id]"
                  :mandatory="item.required==='1'"
                  :multiple="item.multiSelect==='1'"
                  active-class="active">
                  <div class="d-flex flex-wrap">
                    <v-item :key="'mod111'+index" v-for="(s,index) in item.select" v-slot="{active,toggle}">
                      <v-card :color="active?'primary black--text':'grey darken-3'" @click="toggle" class="mr-2 mb-2 pa-2"
                              elevation="0">
                        <div class="text-body-2 text-center d-flex  justify-center">
                          <div>{{ s.text }}</div>
                          <div>{{ s.priceInfo }}</div>
                        </div>

                      </v-card>
                    </v-item>
                  </div>

                </v-item-group>
              </div>
            </template>
          </div>
          <div style="height: 96px;width: 100%" class="flex-shrink-0"></div>
        </template>
        <div style="height: 144px;width: 100%" class="flex-shrink-0"></div>
        <v-card tile color="grey darken-3" style="position: fixed;bottom: 0;width: 100%;" class="pa-2 px-4">
          <div class="d-flex align-center">
            <div>
              <div class="text-h6 font-weight-bold">
                {{ realPrice | priceDisplay }}
              </div>
              <div v-if="selectedModString" class="d-flex align-center">
                <v-icon small class="mr-2 text--secondary">mdi-heart-outline</v-icon>
                <div class="text-caption text--secondary">{{ selectedModString }}</div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class=" d-flex align-center grey darken-3 mr-1" style="border-radius: 24px;">
              <v-card color="grey darken-2" class="pa-3" style="border-radius: 24px" elevation="0"
                      @click="count>1?count--:null">
                <v-icon>mdi-minus</v-icon>
              </v-card>
              <div class="pa-2 mx-1">
                {{ count }}
              </div>
              <v-card color="grey darken-1" class="pa-3" style="border-radius: 24px" elevation="0"
                      @click="count++">
                <v-icon>mdi-plus</v-icon>
              </v-card>
            </div>

          </div>
          <div class="d-flex mt-2">
            <v-card @click="cancel" style="border-radius: 24px" color="error"
                    class="pa-3 mr-2 d-flex align-center">
              <v-icon>
                mdi-close
              </v-icon>
            </v-card>
            <v-card class="d-flex align-center justify-center pa-3 px-6 flex-grow-1" style="border-radius: 24px"
                    elevation="0"
                    color="primary black--text" @click="submitModification"
                    dark>
              <v-icon class="mr-2" color="black">mdi-basket-plus</v-icon>
              <div class="text-body-1 font-weight-bold">
                {{ realPrice * count|priceDisplay }}
              </div>
            </v-card>
          </div>


        </v-card>
      </div>

    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "DishDetailSheet",
  props: {show: {}, dish: {}, imageRoot: {}},
  data: function () {
    return {
      selected: false,
      mod: {},
      count: 1,
    };
  },

  computed: {
    options() {
      return this.dish.modInfo ?? []
    },
    hasImage() {
      return ['png', 'jpg', 'jpeg'].includes(this.dish?.image?.toLowerCase()?.split('.').pop())
    },
    computedOption: function () {
      const realModInfo = []
      this.options.forEach(item => {
        item.select = []
        if (!Array.isArray(item.selectName)) {
          item.selectName = item.selectName.split(',')
          item.selectValue = item.selectValue.split(',')
          item.priceInfo = item.priceInfo.split(',')
        }
        item.selectName.forEach((name, index) => {
          item.select.push({
            text: `${name}`,
            value: item.selectValue[index],
            price: parseFloat(item.priceInfo[index]),
            priceInfo: parseFloat(item.priceInfo[index]) === 0 ? '' : `(€${parseFloat(item.priceInfo[index]).toFixed(2)})`
          })
        })
        realModInfo.push(item)
      })
      return realModInfo
    },
    realMod: function () {
      const realMod = []
      for (const groupId in this.mod) {
        for (const selectIndex of [this.mod[groupId]].flat()) {
          realMod.push({
            groupId,
            selectIndex
          })
        }
      }
      return realMod
    },
    addPrice: function () {
      return this.computedOption.length > 0 ? this.realMod.reduce((total, i) => {
        total += this.findModItemUseGroupIdAndIndex(i.groupId, i.selectIndex)?.price ?? 0
        return total
      }, 0) ?? 0 : 0
    },
    selectedModString: function () {
      return this.computedOption.length > 0 ? this.realMod
        .map(it => this.findModItemUseGroupIdAndIndex(it.groupId, it.selectIndex)?.text ?? '').join(', ') : ''
    },
    realPrice: function () {
      return parseFloat(this.dish.price) + parseFloat(this.addPrice)
    },
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    findModItemUseGroupIdAndIndex(groupId, index) {
      return this.computedOption.find(g => parseInt(g.id) === parseInt(groupId)).select[parseInt(index)]
    },
    cancel() {
      this.$emit('modification-cancel')
      this.count = 1
      this.mod = {}
    },
    submitModification() {
      this.$emit('modification-submit', this.mod, this.count)
      this.mod = {}
      this.count = 1
    }
  }
}
</script>

<style scoped>
.contentContainer {
  padding: 16px;
}

::-webkit-scrollbar {
  display: none;
}


.name {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: rgb(32, 33, 37);
  margin: 0px;
}

</style>
