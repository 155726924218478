<template>
  <div>
    <div class="green lighten-4">
      <v-responsive :aspect-ratio="16/9"></v-responsive>
    </div>
    <div class="pa-4">
      <v-card v-if="!isLogin" class="mt-n16 pa-3 py-4 d-flex align-center">
        <div>
          <div class="text-body-1 font-weight-bold">{{ $t('GreetingVip') }}</div>
          <div class="text-caption">{{ $t('LoginBonusPoints') }}</div>
        </div>
        <v-btn @click="startLogin" color="primary black--text ml-4 mr-3">{{ $t('LoginNow') }}</v-btn>
      </v-card>
      <v-card v-else flat class="mt-n16 pa-3 py-4 d-flex align-center" style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/woman.svg"></v-img>
        </v-avatar>
        <div class="d-block overflow-hidden ml-4">
          <div class="text-body-1 font-weight-black">
            {{ $t('MyNotes') }} {{ 0 }}
          </div>
          <div
            class="text-truncate text-caption text--secondary"
          >
            {{ $t('RedeemableCouponsLimitedMerchandise') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div @click="showUserCode=true" class="mr-4 d-flex flex-column align-center justify-center">
          <v-icon size="36">mdi-qrcode</v-icon>
        </div>
      </v-card>
      <v-card class="pa-3 mt-4">
        <div class="text-capitalize text-body-1 font-weight-bold">
          {{ $t('MyFortune') }}
        </div>
        <div class="mt-2"
             style="display: grid;grid-template-columns: repeat(3,minmax(0,1fr));grid-gap: 16px">
          <v-card @click="displayComingPage">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="4/3">
              <div
                class="d-block overflow-hidden"
              >
                <div class="text-body-1 font-weight-bold mt-2 text-capitalize">{{$t('Integral') }}</div>
                <div class="text-caption text-truncate text--secondary mt-2 text-capitalize">{{ $t('ViewCredits') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card @click="displayComingPage">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="4/3">
              <div
                class="d-block overflow-hidden"
              >
                <div class="text-body-1 font-weight-bold mt-2 text-capitalize">{{$t('Coupon') }}</div>
                <div class="text-caption text-truncate text--secondary mt-2 text-capitalize">{{ $t('ViewCoupons') }}</div>
              </div>
            </v-responsive>
          </v-card>
        </div>
      </v-card>
      <v-card class="pa-3 mt-4">
        <div class=" text-body-1 font-weight-bold text-capitalize">
          {{ $t('MyFunction') }}
        </div>
        <div class="mt-2"
             style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 16px">
          <v-card @click="displayComingPage">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-account-edit</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('ContactInformation') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card @click="$store.commit('showAnnouncement',true)">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-bulletin-board</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('StoreAnnouncement') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card @click="displayComingPage">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-bell</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('SystemNotification') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card @click="displayComingPage">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-ticket-percent</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('RedeemOffers') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card v-if="isLogin" @click="showUserCode=true">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-qrcode</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('MemberCode') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card @click="$store.commit('changeAGB',true)">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-shield-check</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('PrivacyPolicy') }}</div>
              </div>
            </v-responsive>
          </v-card>
          <v-card v-if="isLogin" @click="logOut">
            <v-responsive class="d-flex align-center text-center" :aspect-ratio="1">
              <div
                class="d-block overflow-hidden"
              ><v-icon class="mt-2">mdi-logout</v-icon>
                <div class="text-caption text-truncate mt-2 text-capitalize">{{ $t('Logout') }}</div>
              </div>
            </v-responsive>
          </v-card>
        </div>
      </v-card>
    </div>

    <v-bottom-sheet v-model="showShopAnnouncement">

    </v-bottom-sheet>
    <v-bottom-sheet v-model="showUserCode">
      <user-code-page/>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UserCodePage from "@/pages/UserCodePage.vue";
import {logOut} from "@/plugins/fire-base";

export default {
  name: "MyPage",
  components: {UserCodePage},
  computed: {
    ...mapState(['isLogin', 'userInfo'])
  },
  data: function () {
    return {
      showUserCode: false,
      showShopAnnouncement: false,
      showAGB: false
    };
  },
  methods: {
    logOut,
    displayComingPage() {
      this.$store.commit('showComingPage', true)
    },
    startLogin() {
      this.$store.commit('showLoginPage', true)
    }
  }
}
</script>

<style scoped>

</style>
