import {getRestaurantInfo} from '@/dataLayer/service/api'
import hillo from "hillo";

export const defaultRestaurantInfo = {
  adress1: '',
  adress2: '',
  city: '',
  name: '',
  postCode: '',
  currentlyOpening: 0,
  emailAddress: '',
  inhalterLastName: '',
  inhalterName: '',
  latitude: '',
  longitude: '',
  state: '',
  takeawayPriceModification: 0,
  taxNumber: '',
  telephone: ''
}


let RestaurantInfo = null

export async function getCurrentRestaurantInfo() {
  if (RestaurantInfo == null) {
    RestaurantInfo = Object.assign({}, defaultRestaurantInfo, (await getRestaurantInfo()))
    const reg = new RegExp('<BR>', 'g')
    RestaurantInfo.name = RestaurantInfo.name.replace(reg, ' ').replace('-', '‧')
    RestaurantInfo.currentlyOpening = parseInt(RestaurantInfo.currentlyOpening) ?? 1
  }
  return RestaurantInfo
}


export async function getBannerImageList() {
  return (await hillo.get('Restaurant.php?op=showAllRestaurantImage')).content
}
