import {collection, getDocs, query, where, doc, getDoc} from "firebase/firestore";
import {NewFireBaseStore} from "@/plugins/fire-base";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";


const orderPath = 'order'

export async function getActiveProductCode () {
  const orders = await getCurrentStoreSubscriptions()
  const activeOrder = []
  for (const order of orders) {
    const sub = order.sub
    if (sub.status === 'active') {
      activeOrder.push(order.order)
    }
  }
  return activeOrder.map(it => it.productList.filter(that => that.metadata.type === 'software')
    .map(that => that.metadata.code)).flat()
}

export async function getCurrentStoreSubscriptions () {
  return await getSubscriptionsByStore(GlobalConfig.DeviceId)
}

export async function getSubscriptionsByStore (storeId) {
  const result = []
  const orders = await getStoreOrders(storeId)
  console.log(orders, 'orders')
  for (const order of orders) {
    const sub = await getUserSubscriptionDetail(order.userId, order.subscriptionId)
    result.push({
      order,
      sub,
    })
  }
  return result
}

export async function getUserSubscriptionDetail (userId, subId) {
  return (await getDoc(doc(NewFireBaseStore, 'customers', userId, 'subscriptions', subId))).data()
}

export async function getStoreOrders (id) {
  const collectionRef = collection(NewFireBaseStore, orderPath)
  const res = query(collectionRef, where('storeId', '==', id))
  return await resultOfId(res)
}

export async function resultOfId (query, logLabel = '') {
  const res = (await getDocs(query)).docs
    .map(contentOfId)
  if (logLabel) {
    console.log(res, logLabel)
  }
  return res
}

export function contentOfId (doc) {
  return {
    ...doc.data(),
    id: doc.id,
  }
}
