<template>
  <div style="border-radius: 8px">
    <v-img style="border-radius: 8px" :width="width" contain :aspect-ratio="1" :src="imageRoot+image">
      <template #placeholder>
        <v-card color="black">
          <v-responsive :aspect-ratio="1">
            <div style="height: 100%" class="d-flex align-center justify-center">
              <v-icon color="grey darken-1">mdi-image</v-icon>
            </div>
          </v-responsive>
        </v-card>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "DishImage",
  props: {
    image: {},
    imageRoot: {},
    width:{
      default:'128'
    }
  }
}
</script>

<style scoped>

</style>
