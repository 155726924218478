import firebase from 'firebase/compat'
import {collection, getDocs, query} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth'
import store from "@/store";
import {getStripePayments} from "@stripe/firestore-stripe-payments";

const oldFirebaseConfig = {
  apiKey: 'AIzaSyATTtRB1PlOC-At0-ndKqap4RABwwCc6ew',
  authDomain: 'aaden-boss-test.firebaseapp.com',
  projectId: 'aaden-boss-test',
  storageBucket: 'aaden-boss-test.appspot.com',
  messagingSenderId: '604566804841',
  appId: '1:604566804841:web:a82cb7788517358ceb45f8',
  measurementId: 'G-CST41XTVMJ'
}

const newFirebaseConfig = {
  apiKey: 'AIzaSyCtvQ3d-HAtHTUg_-505c-qXRnlz8RlZeg',
  authDomain: 'aaden-saas.firebaseapp.com',
  projectId: 'aaden-saas',
  storageBucket: 'aaden-saas.appspot.com',
  messagingSenderId: '169167876904',
  appId: '1:169167876904:web:b83934e5a34d1cbfcc161d',
  measurementId: 'G-QRPH7NLDZS',
}
const websiteUrl = 'websiteUrls'
export const oldFirebase = firebase.initializeApp(oldFirebaseConfig,'oldFirebase')
export const newFirebase = firebase.initializeApp(newFirebaseConfig,'newFirebase')
export const OldFireBaseStore = firebase.firestore(oldFirebase)
export const NewFireBaseStore = firebase.firestore(newFirebase)

export const FireBaseAuth = firebase.auth(newFirebase)

export const StripePayment = getStripePayments(newFirebase, {
  customersCollection: 'customers',
  productsCollection: 'products',
})

FireBaseAuth.onAuthStateChanged(user => {
  console.log(user)
  if (user?.uid) {
    store.commit('changeLogIn', true)
    store.commit('changeUserInfo', user)
    store.commit('showLoginPage',false)
  } else {
    store.commit('changeLogIn', false)
  }

})

export async function logOut(){
  await FireBaseAuth.signOut()
}

export async function loginWithGoogle() {
  const provider = new GoogleAuthProvider()
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function loginWithApple() {
  const provider = new OAuthProvider('apple.com')
  return await signInWithRedirect(FireBaseAuth, provider)
}

export async function resetPasswordEmail(email) {
  return await sendPasswordResetEmail(FireBaseAuth, email)
}

export async function loginWithEmailAndPassword(email, password) {
  return await signInWithEmailAndPassword(FireBaseAuth, email, password)
}

export async function createUserWithEmail(email, password) {
  return await createUserWithEmailAndPassword(FireBaseAuth, email, password)
}

export async function checkNewRecords () {
  const collectionRef = collection(NewFireBaseStore, websiteUrl)
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}

export async function checkOldRecords () {
  const collectionRef = collection(OldFireBaseStore, websiteUrl)
  const res = query(collectionRef)
  return (await getDocs(res)).docs.map(it => it.data())
}

export function getCurrentUserId() {
  return FireBaseAuth.currentUser?.uid
}

