<template>
  <div>
    <div @click="$router.push('/menu')">
      <v-responsive :aspect-ratio="4/3">
        <v-carousel
          :show-arrows="false"
          cycle
          delimiter-icon="mdi-circle-small"
          height="100%"
          hide-delimiter-background>
          <v-carousel-item
            v-for="(image) in imageList"
            :key="image.id"
          >
            <v-img :aspect-ratio="4/3" :src="GlobalConfig.imageRoot+image.image"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-responsive>


    </div>
    <div class="mt-n4 mx-6">
      <v-card v-if="!tableUsing" class="pa-2" flat style="border-radius: 12px;display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px">
        <div
          class="d-flex pa-4 flex-column justify-center align-center"
          @click="openTable"
        >
          <div class="text-h6 text-truncate font-weight-black amber--text text--lighten-4" style="max-width: 145px;">
            {{ $t('ScanCodeDineIn') }}

          </div>
          <div class="text-body-2 text-truncate text--secondary" style="max-width: 110px;">
            {{ $t('DeliciousFoodGlance') }}
          </div>
          <v-img class="mt-3" contain src="img/homePage/restaurant.svg" width="64"/>
        </div>
        <div
          class="d-flex pa-4 flex-column justify-center align-center"
          @click="openTable"
        >
          <div class="text-h6 text-truncate font-weight-black amber--text text--lighten-4" style="max-width: 145px;">
            {{ $t('PickAndTakeAway') }}
          </div>
          <div class="text-body-2 text-truncate text--secondary" style="max-width: 110px;">
            {{ $t('AdditionalDiscount') }}
          </div>
          <v-img class="mt-3" contain src="img/homePage/shopping-bag.svg" width="64"/>
        </div>
      </v-card>
      <v-card v-else class="pa-4 py-6 d-flex align-center" flat style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/restaurant.svg"></v-img>
        </v-avatar>
        <div class="d-block overflow-hidden ml-4">
          <div class="text-body-1 font-weight-black">
            {{ $t('CurrentTableNumber') }}{{ currentOrderInfo.name }}
          </div>
          <div
            class="text-truncate text-caption text--secondary"
          >
            {{ $t('ContinueEnjoyFood') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="primary black--text" @click="$router.push('/menu')">{{ $t('Go') }}</v-btn>
      </v-card>
      <v-card v-if="isLogin" class="mt-4 pa-4 px-4 d-flex align-center" flat style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/woman.svg"></v-img>
        </v-avatar>
        <div class="d-block overflow-hidden ml-4 mr-2">
          <div class="text-body-1 font-weight-black">
            {{ $t('MyNotes') }} {{ 0 }}
          </div>
          <div
            class="text-truncate text-caption text--secondary"
          >
            {{ $t('RedeemableCouponsLimitedMerchandise') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="mr-4 d-flex flex-column align-center justify-center" @click="showUserCode=true">
          <v-icon size="36">mdi-qrcode</v-icon>
        </div>
      </v-card>
      <v-card v-else class="mt-6 pa-4 px-4 d-flex align-center" flat style="border-radius: 12px">
        <v-avatar size="36">
          <v-img src="img/homePage/woman.svg"></v-img>
        </v-avatar>
        <div class="d-block overflow-hidden ml-4 mr-1">
          <div class="text-body-2 font-weight-black">
            {{ $t('WelcomeLoginBecomeMember') }}
          </div>
          <div
            class="text-truncate text-caption text--secondary"
          >
            {{ $t('EnjoyPointsAndBenefits') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="primary black--text" small @click="startLogin">{{ $t('LoginNow') }}</v-btn>
      </v-card>
      <div class="mt-6" style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px">
        <v-card class="pa-4 d-flex align-center" flat style="border-radius: 12px" @click="displayComingPage">
          <v-img class="flex-grow-0" src="img/homePage/voucher.svg" width="36"></v-img>
          <div class="d-block overflow-hidden">
            <div class="text-truncate text-body-1 ml-3 text-capitalize">{{ $t('Coupon') }}</div>
          </div>
        </v-card>
        <v-card class="pa-4 d-flex align-center" flat style="border-radius: 12px" @click="displayComingPage">
          <v-img class="flex-grow-0" src="img/homePage/profits.svg" width="36"></v-img>
          <div class="d-block overflow-hidden">
            <div class="text-truncate text-body-1 ml-3 text-capitalize">{{ $t('RechargeCredit') }}</div>
          </div>
        </v-card>
      </div>
    </div>

    <v-bottom-sheet v-model="showUserCode">
      <user-code-page/>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="showHotPotOpenTablePage" fullscreen persistent scrollable>
      <v-card color="black" tile>
        <hotpot-open-table-page @close="showHotPotOpenTablePage=false" @confirm="openTable"/>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import HotpotOpenTablePage from "@/pages/HotpotOpenTablePage.vue";
import {getTableInfo, openTableWithScanQR} from "@/dataLayer/service/api";
import {mapState} from "vuex";
import UserCodePage from "@/pages/UserCodePage.vue";
import {getBannerImageList} from "@/dataLayer/repository/restaurantInfo";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";
import {getActiveProductCode} from "@/plugins/ownProducts";

export default {
  name: "IndexPage",
  components: {UserCodePage, HotpotOpenTablePage},
  data: function () {
    return {
      showHotPotOpenTablePage: false,
      loggedIn: false,
      currentOrderInfo: null,
      showUserCode: false,

      GlobalConfig,
      imageList: [],
    };
  },
  computed: {
    ...mapState(['isLogin', 'userInfo']),
    tableUsing() {
      return this.currentOrderInfo?.usageStatus === '1'
    }
  },
  async mounted() {
    await this.reload()
  },
  destroyed() {
    clearInterval(this.handel)
  },
  methods: {
    async reload() {
      if (GlobalConfig.SaaSVersion) {
        const res = await getActiveProductCode()
        if (!res.includes('scanToOrder')) {
          await this.$router.push({name: 'notPaid'})
        }
      }
      this.currentOrderInfo = (await getTableInfo())
      this.imageList = await getBannerImageList()
      if (localStorage?.firstShow === '1') {
        await this.openAnnouncement()
        localStorage.setItem('firstShow', '0')
      }
    },
    hashcode(code) {
      let hash = 0, i, chr, len;
      if (code.length === 0) return hash;
      for (i = 0, len = code.length; i < len; i++) {
        chr = code.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
      }
      return hash;
    },
    async openAnnouncement() {
      // const res = await getRestaurantInfo()
      // const today = dayjs().format('YYYY-MM-DD')
      // const announce = this.hashcode(res.buffetAnnouncementBody).toString()
      // const oldDate = localStorage.getItem('date')
      // const oldAnnounce = localStorage.getItem('announce')
      // const timespan = dayjs(today).diff(oldDate, 'day')
      // if (!oldDate || timespan > 7) {
      //   localStorage.setItem('date', today)
      //   this.$store.commit('showAnnouncement', true)
      // }
      // if (oldAnnounce && oldAnnounce === announce) {
      //   return
      // }
      // localStorage.setItem('announce', announce)
      this.$store.commit('showAnnouncement', true)
    },
    displayComingPage() {
      this.$store.commit('showComingPage', true)
    },
    startLogin() {
      this.$store.commit('showLoginPage', true)
    },
    async openTable() {
      const res = await openTableWithScanQR((await getTableInfo()).id)
      console.log(res)
      if (res.status === 'good') {
        await this.$router.push('/menu')
      }
    }
  }
}
</script>

<style scoped>

</style>
