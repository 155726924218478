import {loadBaseConfig} from "aaden-base-model/lib/Models/GlobalSettings";
import hillo from "hillo";
import IKUtils from "innerken-js-utils";
import {LocalSettingManager} from "biewangle";
import {checkNewRecords, checkOldRecords} from "@/plugins/fire-base";
import {getBaseAndUrlForDeviceId} from "@/dataLayer/service/api";

const defaultConfig = require("@/assets/config.json");
let GlobalConfig = Object.assign({ locale: "de" }, defaultConfig);
window.Config = GlobalConfig;

export async function loadConfig() {
  GlobalConfig = Object.assign(
    GlobalConfig,
    await loadBaseConfig(defaultConfig)
  );
  const shortName = location.hostname.split(".")[0];
  console.log(shortName);
  const newRecords = (await checkNewRecords()).find(s => s.subdomainName === shortName)
  const oldRecords = (await checkOldRecords()).find(s => s.subdomainName === shortName)
  console.log((await checkOldRecords()), '(await checkOldRecords())')
  console.log((await checkNewRecords()), '(await checkNewRecords())')
  let record
  if (newRecords) {
    record = newRecords
    GlobalConfig.SaaSVersion = true
  } else {
    record = oldRecords
  }
  if (record) {
    GlobalConfig.DeviceId = record.deviceId;
  }
  console.log(record.deviceId, 'record.deviceId')
  const realUrl = (await getBaseAndUrlForDeviceId(record.deviceId)).url
  hillo.initial(realUrl + '/PHP/')
  GlobalConfig.imageRoot = getImgRoot(realUrl);
  GlobalConfig.resourceRoot = realUrl + "/Resource/";
  localStorage.setItem('firstShow', '1')
}

export function getImgRoot(base) {
  return base + GlobalConfig.imgRoot;
}

export const TableName =
  IKUtils.getQueryString("tableName") || IKUtils.getQueryString("TableName");
console.log(TableName, "Table Name");

export const Remember = LocalSettingManager.config({
  requestLocale: "de",
  locale: "de"
});

export default GlobalConfig


