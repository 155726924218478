import { render, staticRenderFns } from "./IndexPage.vue?vue&type=template&id=ecb3c01c&scoped=true&"
import script from "./IndexPage.vue?vue&type=script&lang=js&"
export * from "./IndexPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb3c01c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBottomSheet,VBtn,VCard,VCarousel,VCarouselItem,VIcon,VImg,VResponsive,VSpacer})
