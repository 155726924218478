import en from '../translations/en-US.json'
import es from '../translations/es.json'
import pt from '../translations/pt-PT.json'
import de from '../translations/de.json'
import fr from '../translations/fr-FR.json'
import nl from '../translations/nl.json'
import ar from '../translations/ar.json'
import ko from '../translations/ko.json'
import ru from '../translations/ru.json'
import zh from '../translations/zh-Hans.json'
import ja from '../translations/ja.json'
import pl from '../translations/pl.json'

const supported = ['en', 'es', 'pt', 'de', 'fr', 'ar', 'ko', 'ru', 'zh', 'ja', 'pl', 'nl']
let locale = 'de'

try {
  // get browser default language
  const {0: browserLang} = navigator.language.split('-')
  if (supported.includes(browserLang)) locale = browserLang
} catch (e) {
  console.log(e)
}

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'de',

  // availabled locales for user selection
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'nl',
    flag: 'nl',
    label: 'Netherlands',
    messages: nl
  }, {
    code: 'es',
    flag: 'es',
    label: 'Español',
    messages: es
  }, {
    code: 'pt',
    flag: 'pt',
    label: 'Português',
    messages: pt
  }, {
    code: 'de',
    flag: 'de',
    label: 'Deutsch',
    messages: de
  }, {
    code: 'fr',
    flag: 'fr',
    label: 'Français',
    messages: fr
  }, {
    code: 'ar',
    flag: 'sa',
    label: 'العربية',
    messages: ar
  }, {
    code: 'ko',
    flag: 'kr',
    label: '한국어',
    messages: ko
  }, {
    code: 'ru',
    flag: 'ru',
    label: 'русский',
    messages: ru
  }, {
    code: 'zh',
    flag: 'cn',
    label: '中文',
    messages: zh
  }, {
    code: 'ja',
    flag: 'jp',
    label: '日本語',
    messages: ja
  }, {
    code: 'pl',
    flag: 'pl',
    label: 'Polskie',
    messages: pl
  }]
}
