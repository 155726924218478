<template>
  <div class="fill-height" style="width: 100%">
    <div class="fill-height d-flex flex-column align-center justify-center">
      <div class="d-flex justify-center" style="width: 100%;max-width: 300px;">
        <lottie-animation :animation-data="require('@/assets/rocket.json')" loop></lottie-animation>
      </div>
      <div class="pa-6 mt-4" style="width: 100%">
        <div class="text-h4 decoText font-weight-bold">{{ $t('CurrentTableNumber') }} {{ tableName }}</div>
        <div class="text-body-1 mt-4">{{ $t('OrderingExperience') }}</div>
        <v-btn block class="mt-4" color="primary black--text" elevation="0"
               x-large @click="openTable">
          <v-icon left>mdi-run-fast</v-icon>
          {{ $t('OrderNow') }}
        </v-btn>
      </div>
    </div>
    <v-bottom-sheet v-model="showHotPotOpenTablePage" fullscreen persistent scrollable>
      <v-card color="black" tile>
        <hotpot-open-table-page
          @close="showHotPotOpenTablePage=false"
          @confirm="openTable"
        />
      </v-card>
    </v-bottom-sheet>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCategoryWithDishes, getTableInfo, getTableName, openTableWithScanQR} from "@/dataLayer/service/api"
import HotpotOpenTablePage from "@/pages/HotpotOpenTablePage.vue"; // import lottie-web-vue


export default {
  components: {HotpotOpenTablePage, LottieAnimation},
  name: "LoadingPage",
  data: function () {
    return {
      tableName: getTableName(),
      showHotPotOpenTablePage: false
    }
  },
  methods: {
    async openTable() {
      const res = await openTableWithScanQR((await getTableInfo()).id)
      console.log(res)
      if (res.status === 'good') {
        await this.$router.push('/')
        await this.$router.push('/menu')
        this.showHotPotOpenTablePage = false
      }
    }
  },
  async mounted() {
    await getCategoryWithDishes('DE')
    const tableStatus = await getTableInfo()
    if (tableStatus?.usageStatus === '1') {
      await this.$router.push('/menu')
    }
  }
}
</script>

<style scoped>

</style>
