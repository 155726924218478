<template>
  <v-card elevation="0" style="border-radius: 16px" class="mx-4 my-4" @click="$emit('click')">
    <v-lazy min-height="136">
      <div class="d-flex align-center">
        <div style="border-radius: 16px;" class="flex-grow-0 ml-2 black">
          <dish-image :image="dish.image" :image-root="imageRoot"/>
        </div>

        <div style="width: 100%;" class="d-flex flex-column pa-2">
          <div class="text-body-1 font-weight-bold">
            {{ dish.code.replaceAll('.', '') }}.<span class="text-capitalize">{{ dishName }}</span>
          </div>
          <div class="text-body-2 mt-1 text--secondary"
               v-html="dish.dishDesc ? dish.dishDesc : $t('EmptyMenuDesc') "></div>
          <v-spacer></v-spacer>
          <div class="d-flex align-center mt-1" style="width: 100%">
            <div class="text-body-2 font-weight-medium ">
              {{ dish.price | priceDisplay }}
            </div>
            <v-spacer></v-spacer>
            <template v-if="dish.haveMod!=='0'&&count>0">
              <v-card color="primary  black--text" class=" text-caption"
                      style="border-radius: 24px;padding: 0px 6px">
                &times; {{ count }}
              </v-card>
            </template>
            <template v-if="dish.haveMod==='0'">
              <v-icon @click.stop="$emit('minus')" color="primary darken-2">mdi-minus-circle</v-icon>
              <div style="min-width: 24px;" class="text-center text-body-1">
                {{ count }}
              </div>
            </template>
            <v-icon v-if="dish.haveMod==='0'||count===0"
                    @click.stop="dish.haveMod==='0'?$emit('add'):$emit('click')"
                    color="primary">
              mdi-plus-circle
            </v-icon>

          </div>


        </div>

      </div>
    </v-lazy>
  </v-card>
</template>

<script>
import DishImage from "@/components/DishImage.vue";

export default {
  name: "DishCard",
  components: {DishImage},
  props: {
    imageRoot: {},
    dish: {},
    count: {},
  },
  computed: {
    hasImage() {
      return ['png', 'jpg', 'jpeg'].includes(this.dish?.image?.toLowerCase()?.split('.').pop())
    },
    dishName() {
      return this.dish.name ?? this.dish.dishName
    },
    code() {
      return this.dish.code.replaceAll('.', '')
    }
  }
}
</script>

<style scoped>

</style>
