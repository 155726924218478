import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import GlobalConfig, {loadConfig} from '@/dataLayer/repository/GlobalSettingManager'
import IKUtils from 'innerken-js-utils'
import store from './store'

Vue.config.productionTip = false

Vue.filter('priceDisplay',
  function (price) {
    return parseFloat(price).toFixed(2).replace('.', ',') + ' €'
  }
)


const deviceId = IKUtils.getQueryString("deviceId") || IKUtils.getQueryString("DeviceId")

// const tableName = IKUtils.getQueryString("TableName")


async function init() {
  if (deviceId !== null) {
    GlobalConfig.Base = deviceId
    await localStorage.setItem('deviceId', deviceId)
  }
  await loadConfig()
  new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
}

init()

